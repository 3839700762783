// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import {EnvironmentType} from './environment-type';

export const environment: EnvironmentType = {
  production: false,
  apiUrl: 'http://localhost:5502',
  clientSecret: '83FE861B-FD47-40CF-BB4F-ECBE94845E77',
  additionalFields: [],
};
