import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from './environments/environment';
import {merge} from 'lodash';

const httpRequest = new XMLHttpRequest();

httpRequest.onreadystatechange = function() {
  if (httpRequest.readyState === XMLHttpRequest.DONE) {
    if (httpRequest.status === 200) {
      const environmentSettings = JSON.parse(httpRequest.responseText);
      merge(environment, environmentSettings);
      if (environment.production) {
        enableProdMode();
      }
      import('./app/app.module').then((m) => {
        platformBrowserDynamic()
          .bootstrapModule(m.AppModule)
          .catch((err) => console.log(err));
      });
    } else {
      console.error('there was a problem with loading the environment settings');
    }
  }
};
httpRequest.open('GET', 'assets/settings.json');
httpRequest.send();
